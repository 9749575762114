import { DependencyList, useLayoutEffect } from "react";

export function useWindowEventEffect<K extends keyof WindowEventMap>(
    eventName: K,
    callback: (event: WindowEventMap[K]) => void,
    options: AddEventListenerOptions = {},
    dependencies: DependencyList = [],
): void {
    if (typeof window === "undefined") {
        return;
    }

    useLayoutEffect(() => {
        window.addEventListener(eventName, callback, options);
        return () => {
            window.removeEventListener(eventName, callback, options);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventName, callback, options, ...dependencies]);
}

export function useOnEscapeKey(callback: () => void, dependencies: DependencyList = []): void {
    return useWindowEventEffect(
        "keydown",
        (e) => {
            if (e.key === "Escape") {
                e.preventDefault();
                callback();
            }
        },
        {
            capture: true,
        },
        dependencies,
    );
}

export function useOnEnterKey(callback: () => void, dependencies: DependencyList = []): void {
    return useWindowEventEffect(
        "keydown",
        (e) => {
            if (e.key === "Enter") {
                e.preventDefault();
                callback();
            }
        },
        {
            capture: true,
        },
        dependencies,
    );
}

export function useOnSaveShortcut(callback: () => void, dependencies: DependencyList = []): void {
    return useWindowEventEffect(
        "keydown",
        (e) => {
            // Check if the user pressed "Ctrl+S" or "Command+S"
            if ((e.ctrlKey || e.metaKey) && e.key.toLowerCase() === "s") {
                e.preventDefault();
                callback();
            }
        },
        {
            capture: true,
        },
        dependencies,
    );
}
